import React from 'react';

import Nav from 'react-bootstrap/Nav';

import IconIG from '../../media/instagram-inline.svg';
import IconLI from '../../media/linkedin-inline.svg';
import IconDB from '../../media/dribbble-inline.svg';
import IconGH from '../../media/github-inline.svg';
import IconEM from '../../media/mail-inline.svg';

const Social = () => {
  return (
    <nav className='social'>
      <Nav as='ul'>
        <Nav.Item as='li'>
          <a
            href='https://instagram.com/abeardedpaddler'
            target='_blank'
            rel='external noopener noreferrer'
          >
            <IconIG />
          </a>
        </Nav.Item>
        <Nav.Item as='li'>
          <a
            href='https://www.linkedin.com/in/ryan-srofe'
            target='_blank'
            rel='external noopener noreferrer'
          >
            <IconLI />
          </a>
        </Nav.Item>
        <Nav.Item as='li'>
          <a
            href='https://dribbble.com/ryansrofe'
            target='_blank'
            rel='external noopener noreferrer'
          >
            <IconDB />
          </a>
        </Nav.Item>
        <Nav.Item as='li'>
          <a
            href='https://github.com/ryansrofe'
            target='_blank'
            rel='external noopener noreferrer'
          >
            <IconGH />
          </a>
        </Nav.Item>
        <Nav.Item as='li'>
          <a
            href='mailto:rsrofe@gmail.com'
            target='_blank'
            rel='external noopener noreferrer'
          >
            <IconEM />
          </a>
        </Nav.Item>
      </Nav>
    </nav>
  );
};

export default Social;
