import React from 'react';
// import { Link } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Brands = () => {
  return (
    <section id='career'>
      <Container>
        <Row>
          <Col md={12} lg={9}>
            <h3>
              Currently Design Technology manager at Formidable. Formerly,
              Creative Technology lead at 22Squared.
            </h3>
            <p>
              <strong>
                I’m fortunate to have worked with some amazing brands in my
                career:{' '}
              </strong>
              Publix Supermarkets, The Home Depot, AdventHealth, Southeast
              Toyota, Sweetwater Brewery, Buffalo Wild Wings, NAACP, Columbia
              Care, TIAA Bank, Truist, 22Squared, SeaWorld, Busch Gardens,
              Medieval Times, Dollar & Thrifty Car Rental, BumperCrop, Tampa Bay
              Buccaneers, Tampa Bay Lightning, We Craft Box, OGX, Proganix,
              McDonalds, PODS, Melitta Coffee, McGraw Hill, ProMarine,
              RiskSmith.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Brands;
