import React from 'react';

import useSound from 'use-sound';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import boopSnd from '../../../static/audio/squeeze-toy.mp3';

const Hello = () => {
  const [wobble, setWobble] = React.useState(0);
  const [play] = useSound(boopSnd);

  const handleClick = () => {
    setWobble(1);
    play();
  };

  return (
    <section id='hello'>
      <Container>
        <Row>
          <Col md={12} lg={9}>
            <h1>
              Hi,{' '}
              <button onClick={handleClick} onAnimationEnd={() => setWobble(0)}>
                <span wobble={wobble} role='img' aria-label='hand waving'>
                  👋
                </span>
              </button>{' '}
              I’m Ryan Srofe.
            </h1>
            <h2>
              I combine creative thinking and technical dexterity to design and
              deliver award-winning interactive customer experiences.
            </h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hello;
