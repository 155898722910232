import React from 'react';

import Layout from '../components/global/Layout';
import Container from '../components/global/Container';
import SEO from '../components/global/SEO';

import Hello from '../components/home/Hello';
import Career from '../components/home/Career';
import Contact from '../components/home/Contact';


const Index = ( props ) => {
  
  const metaData = {
    title: `Ryan Srofe`,
    description: `Ryan Srofe Portfolio`
  }

  const pagePath = props.location.pathname;

  return (
    <Layout root={pagePath}>
      <SEO metaData={metaData} pagePath={pagePath} />
      <Container>
        <div id="home">
          <Hello />
          <Career />
          <Contact />
        </div>
      </Container>
    </Layout>
  )
}

export default Index
