import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import Social from '../../components/global/Social';


const Contact = () => {
  return (
    <section id="contact">
      <Container>
        <Row>
          <Col md={12} lg={9}>
            <p><strong>Connect with me!</strong></p>
            <Social />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact
